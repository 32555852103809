let draganddrop = function ($) {

    /**
     * Run answers
     */
    let runAnswers = function () {
        $('body').on('click', '.draganddrop .answers a', function (e) {
            e.preventDefault();
            let _this = this;
            if ($(_this).hasClass('disabled')) return false;
            $(_this).fadeOut(function () {
                $(_this).next().fadeIn();
            });
        });

        $('body').on('click', '.draganddrop-col .answers a', function (e) {
            e.preventDefault();
            let _this = this;
            if ($(_this).hasClass('disabled')) return false;
            $(_this).fadeOut(function () {
                $(_this).next().fadeIn();
            });
        });
    };

    return {
        init: function () {
            runAnswers();
        }
    };
}(jQuery);

$(document).ready(function () {
    draganddrop.init();
});